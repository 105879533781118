window.Confirm = function(options) {

  options = options || {};

  return Swal.fire({
    title: options.title || 'Weet je het zeker?',
    text: options.text || 'Dit kan niet ongedaan gemaakt worden!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: options.confirm || 'Ja',
    cancelButtonText: options.cancelButtonText || 'Nee',
    html: options.html || false,
    confirmButtonColor: '#184d80',
  });
};

$(function() {
  $('.confirm-dialog')
      .click(function(e) {
        var self = $(this);

        if (self.prop('href') || self.prop('type') === 'submit') {
          e.preventDefault();

          var options = {};

          $.each(['title', 'text', 'confirm', 'html'], function(key, value) {
            if (self.data(value)) {
              options[value] = self.data(value);
            }
          });

          window.Confirm(options)
              .then(function(result) {
                if (result.isConfirmed) {
                  if (self.prop('href')) {
                    window.location.href = self.prop('href');
                  } else if (self.prop('type') === 'submit') {

                    if (self.prop('form')) {
                      self.prop('form')
                          .submit();
                    } else {
                      self.closest('form')
                          .submit();
                    }
                  }
                }
              });
        }
      });
});