<template>
  <div class="training-form">
    <template v-if="loaded">

      <div class="bg-gray-200 p-2 mb-1 small">
        <div class="row align-items-center">
          <div class="col-md-6">
            <form method="get">
              <div class="form-inline">

                Toon
                <select v-model="events.per_page" class="form-control form-control-sm mx-2">
                  <option v-for="perPage in [10, 25, 50, 100]" :value="perPage" :selected="events.per_page === perPage">{{ perPage }}</option>
                </select>
                per pagina

              </div>
            </form>
          </div>
          <div class="col-sm-6 text-right d-none">
            <a href="#" class="btn btn-sm btn-light ml-4">Filters (0)</a>
          </div>
        </div>
      </div>

      <b-table striped hover responsive
               :items="events.data"
               :fields="columns"
               :busy="loading"
               :no-local-sorting="true"
               :sort-by.sync="sorting.column"
               :sort-desc.sync="sorting.desc"
               head-variant="light"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner/>
          </div>
        </template>

        <template #cell(event_date)="row">

          {{ row.value | moment('LL') }}

        </template>

        <template #cell(buttons)="row">
          <div class="text-right">
            <a :href="'/event/' + row.item.slug" class="btn btn-light btn-sm" target="_blank">{{ $t('Bekijk formulier') }}</a>
            <a :href="'/admin/events/' + row.item.id + '/submissions'" class="btn btn-light btn-sm">{{ $t('Aanmeldingen') }}</a>
            <a :href="'/admin/events/' + row.item.id" class="btn btn-light btn-sm">{{ $t('Bewerk') }}</a>
            <div @click="destroy(row.item.id)" class="btn btn-danger btn-sm">{{ $t('Verwijder') }}</div>
          </div>
        </template>

      </b-table>

      <div class="bg-gray-200 p-2 mt-1 mb-4 small">
        <div class="row align-items-center">
          <div class="col-md-6">
          </div>
          <div class="col-sm-6 text-right">
            Totaal: {{ events.total }} evenementen
          </div>
        </div>
      </div>

      <b-pagination
          v-model="events.current_page"
          :total-rows="events.total"
          :per-page="events.per_page"
          align="center"
      ></b-pagination>

    </template>
    <template v-else>
      <b-skeleton-table
          :table-props="{ striped: true, hover: true, tableVariant: 'light' }"
          :columns="columns.length"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EventsOverview',

  data() {
    return {
      events: [],
      loaded: false,
      loading: false,
      sorting: {
        column: 'id',
        desc: false,
      },
      filters: {},
      columns: [
        {
          key: 'name',
          label: 'Naam',
          sortable: true,
        },
        {
          key: 'event_date',
          label: 'Datum evenement',
          sortable: true,
          thStyle: {
            width: '260px',
          },
        },
        {
          key: 'buttons',
          label: '',
          thStyle: {
            width: '400px',
          },
        },
      ],
    };
  },
  watch: {
    'events.current_page'(page) {
      this.loading = true;
      this.getEvents(page)
          .then(() => {
            this.loading = false;
          });
    },
    'events.per_page'(page) {
      this.loading = true;
      this.getEvents()
          .then(() => {
            this.loading = false;
          });
    },
    sorting: {
      handler() {
        this.loading = true;
        this.getEvents()
            .then(() => {
              this.loading = false;
            });
      },
      deep: true,
    },
  },
  mounted() {

    this.$api.all([
      this.getEvents(),
    ])
        .then(() => {
          this.loaded = true;
        });
  },

  methods: {
    filter(page = 0) {
      this.loading = true;
      this.getEvents()
          .then(() => {
            this.loading = false;
          });
    },

    destroy(id) {
      Swal.fire({
        title: this.$t('Are you sure?'),
        text: this.$t('Are you sure you want to delete this event?'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Sluiten',
        confirmButtonText: 'Verwijderen',
      })
          .then(((result) => {
            if (result.isConfirmed) {
              this.$api.delete('/admin/events/' + id)
                  .then(response => {
                    this.$toast.fire({
                      icon: 'success',
                      title: 'Event is verwijderd',
                    });
                    this.getEvents();
                  });
            }
          }));
    },

    getEvents(page = 0) {
      return this.$api.get('/admin/events', {params: {page, per_page: this.events.per_page, sort: this.sorting.column, desc: this.sorting.desc, filters: {...this.filters}}})
          .then(response => {
            this.events = response.data;
          });
    },
  },
};
</script>
