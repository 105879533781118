import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import moment from 'moment';
import 'moment/locale/nl';

const Vue = window.Vue = require('vue').default;
window.Swal = Swal.mixin({
  confirmButtonColor: '#BCCC4A',
});

Vue.use(require('vue-moment').default, {
  moment,
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Attach CSRF token
$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]')
        .attr('content'),
  },
});

const Toast = Vue.prototype.$toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});
